$grey1: #202020;
$grey2: #262626;
$grey3: #4a4a4a;
$grey4: #383838;
$grey5: #979797;
$grey6: #dcdcdc;
$black: black;
$white: white;
$cream: #fa9579;
$black-overlay: rgba(0, 0, 0, 0.6);
$white-overlay: rgba(255, 255, 255, 0.6);
$cream-gradient: linear-gradient(90deg, $cream, white 100%);
$cream-gradient-vertical: linear-gradient(180deg, $cream, white 100%);
