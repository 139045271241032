@use '~/styles/modules' as *;

.wrapper {
  position: relative;
  width: fit-content;
  height: fit-content;
  height: 100%;
  width: 100%;

  .linkContainer {
    height: 100%;
    display: block;

    &:hover > div {
      background-color: rgb(255 255 255 / 5%);
    }

    &:hover > span {
      box-shadow: inset 0px 0px 0px 1px rgb(255 255 255 / 10%);
      background: radial-gradient(
        80% 40% at 50% 0%,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }

  .NFTCard {
    font-family: var(--font1);
    color: var(--text-color);
    background-color: var(--background2);
    box-shadow: $cardShadow1, $cardShadow2;
    border-radius: 1.6rem;
    overflow: hidden;
    height: 100%;
    @include flexContainer(column, flex-start, flex-start);

    .collectionDiamond {
      width: 16px;
      height: 16px;
    }

    .topContainer {
      width: 100%;
      padding-bottom: 100%;
      position: relative;
      video {
        border-radius: 1.6rem 1.6rem 0 0;
      }
      @include flexContainer(row, center, center);
    }

    .avatarDropdown {
      position: absolute;
      top: 16px;
      right: 16px;
      z-index: 1;

      @include flexContainer(row, center, center);

      .avatarButtonGroup {
        width: 44px;
        position: relative;
        margin-left: 8px;

        .identicon {
          border-radius: 50%;
          width: 32px;
          height: 32px;
          overflow: hidden;
          z-index: 0;
        }
        > .creator {
          right: 8px;
          z-index: 1;
        }
        .dropdownButton {
          position: absolute;
          right: 0;
          top: 8px;
          width: 16px;
        }
      }
    }

    .bottomContainer {
      width: 100%;
      flex: 1 1 auto;
      padding: 1.6rem;
      padding-top: 2.4rem;
      position: relative;
      @include flexContainer(column, flex-start, flex-start);

      .chipContainer {
        right: 12px;
        top: -1.5rem;
        position: absolute;
        @include flexContainer(row, center, center);
      }
      .collectionChip {
        background: $grey1;
        margin-right: 8px;
        svg {
          margin-right: 0.4rem;
        }
        span {
          @include textGradient();
        }
      }

      .row {
        width: 100%;
        margin: 1px 0;
        position: relative;
        .sold {
          font: $h3;
          color: var(--background6);
        }

        &.top {
          @include flexContainer(column, flex-start, flex-start);
          margin-bottom: 0;
        }

        &.bottom {
          @include flexContainer(row, space-between, stretch);
          padding-top: 1.6rem;
          margin-top: auto;
        }

        .innerRow {
          width: 100%;
          @include truncateText();

          &.collection {
            margin-bottom: 0.4rem;
            font-weight: 600;
            font-size: 11px;
            line-height: 13px;
            letter-spacing: 0.03em;
            text-transform: uppercase;
          }
          &.title {
            font: $h2;
            margin-bottom: 0;
          }

          &.subHeading {
            font: $p2;
            color: var(--background6);
          }

          &.price {
            font: $h3;
            font-size: 14px;
            letter-spacing: -0.02em;
            margin-top: 4px;
            @include textGradient();
          }
        }
      }

      .statusTag {
        .subHeading {
          font: $p2;
          color: var(--background6);
          position: relative;
        }

        .timer {
          font: $h3;
          font-size: 14px;
        }

        .soldState {
          font: $h3;
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }
      .priceContainer {
        display: block;
      }
    }

    .video > video {
      object-fit: cover;
    }
  }
}

.overlay {
  box-shadow: inset 0px 0px 0px 1px rgb(255 255 255 / 5%);
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  transform: translateZ(0); //For border-radius to work on Safari
  background: rgba(243, 222, 222, 0);
  transition: all 0.1s ease-in;
}
