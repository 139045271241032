@use '~/styles/modules' as *;

.ActionButton {
  font: $p2-bold;
  color: var(--text-color);
  padding: 1px;
  background: $cream-gradient;
  width: fit-content;
  border-radius: 3.2rem;
  height: 48px;
  @include flexContainer();

  .children {
    @include flexContainer();
  }

  .innerContainer {
    @include flexContainer(row, center, center);
    width: 100%;
    height: 100%;
    padding: 0.8rem 1.6rem;
    border-radius: 3.2rem;
    background-color: var(--background1);

    .icon {
      width: 1.6rem;
      margin: auto 0.3rem auto auto;
    }

    span {
      text-transform: uppercase;
      // TODO: Revisar cuando haya botones con iconos
      // margin: auto auto auto 0.3rem;
    }
  }

  &:hover {
    .innerContainer {
      background-color: #202020f5;
    }
  }

  &:active,
  &:focus-visible {
    .innerContainer {
      background-color: var(--background-strong);
    }
  }

  &:focus-visible {
    background-color: $white;
    .innerContainer {
      text-decoration: underline;
    }
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}
