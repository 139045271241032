@use '~/styles/modules' as *;

.outerContainer {
  width: 100%;
  height: 4px;
  @include flexContainer(row, left);

  .ColorLine {
    height: 100%;
    width: 100%;
    display: block;
    border-radius: 3rem;
    background: linear-gradient(90deg, #FA9579 0%, #FCB9A7 20%, #FFFFFF 50%, #FCB9A7 80%, #FA9579 100%);
    background-size: 300%;
    background-position: 0 0;
    animation: grow 2.5s ease 3s infinite normal both;
  }

  @keyframes grow {
    0% {
      background-position: 100% 0;
      width: 0%;
    }
    50% {
      background-position: 0 0;
      width: 100%;
      margin-left: 0%;
    }
    100% {
      background-position: 0 0;
      width: 0%;
      margin-left: 100%;
    }
  }
}
