@use '~/styles/modules' as *;

.Tab {
  font: 800 16px/24px Inter, sans-serif !important;
  color: var(--text-color);
  padding: 16px 32px 16px 16px;
  height: 48px;
  border-radius: 16px;
  border: 2px solid;
  position: relative;
  min-width: 180px;
  @include flexContainer(row, flex-start, center);

  &:hover {
    background-color: #4A4A4A;
  }

  &:focus-visible {
    background-color: #4A4A4A;
    border-color: var(--text-color);
  }
}
