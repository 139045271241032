@use '~/styles/modules' as *;

.BidBar {
  font-family: var(--font1);
  color: var(--text-color);
  border-top: 1px solid var(--background4);
  width: 100%;
  padding: 24px 24px 8px 24px;
  position: relative;
  flex-wrap: wrap;
  flex: 0 0 auto;

  @include flexContainer();

  & > * {
    margin-bottom: 16px;
    &:nth-child(2) {
      @include small-screens() {
        margin-left: 24px;
      }
    }
  }

  & > *:nth-child(3) {
    width: 100%;
    @include tablet-screens() {
      flex: 1 1 100%;
    }
    @media (min-width: 1170px) {
      margin-top: 0;
      flex: 1 0;
    }
    margin-left: auto;
  }

  & > button {
    @media (min-width: 1170px) {
      flex: 0 0 auto !important;
    }
  }

  .alreadyBidderMsg {
    font-weight: 600;
    font-size: 14px;
    text-align: right;
    width: 100%;
    text-align: left;
    @include tablet-screens() {
      text-align: left;
    }
    @media (min-width: 1170px) {
      text-align: right;
    }
  }

  .unavailableBidOrBuyContainer {
    flex: 1 1 100%;
  }

  .loadingContainer {
    width: 100%;
    @include flexContainer(column, space-between, flex-start);

    h3 {
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      margin-bottom: 16px;
    }
  }

  .countdownContainer {
    p {
      margin-bottom: 0.4rem;
    }
  }

  .column {
    .label {
      font: $p2;
      color: var(--background6);
      margin-bottom: 0.4rem;
      @include flexContainer(row, flex-start, center);

      .bidHistoryButton {
        @include flexContainer(row, flex-start, center);
        margin-left: 2px;
      }

      .listIcon {
        width: 1.5rem;
        margin: 0 0.2rem;
        fill: $cream;
      }

      .userName {
        font: $p1;
        color: var(--text-color);
      }
    }

    .price {
      font-weight: 600;
      font-size: 16px;
      letter-spacing: -0.02em;
      @include textGradient();

      @include small-screens() {
        font-size: 18px;
      }
    }

    .countDown {
      color: var(--text-color);
      font-weight: 600;
      font-size: 16px;
      letter-spacing: -0.02em;
      @include flexContainer(row, space-between, center);

      @include small-screens() {
        font-size: 18px;
      }
    }
  }

  .buttonContainer {
    width: 30%;
    @include flexContainer(row, flex-end, center);

    .spinner {
      fill: $cream;
      stroke: $cream;
      width: 2rem;
    }

    .outcome {
      width: 2rem;
      stroke: $cream;
    }
  }
}

.bidButton {
  min-width: 100px;
  width: auto !important;
}
