.video {

    > video{
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 1.6rem 1.6rem 0 0;

    }

    &.collectionFilter {

        video {
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            border-radius: 0;
            -khtml-border-radius: 0;
        }
    }

    &.upcomingFilter {

        video {
            -webkit-border-radius: 1.6rem;
            -moz-border-radius: 1.6rem;
            border-radius: 1.6rem;
            -khtml-border-radius: 1.6rem;
        }
    }
}
