@use '~/styles/modules' as *;

.Chip {
  color: var(--text-color);
  padding: 1px;
  width: fit-content;
  border-radius: 8px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: $cream-gradient;
  background-color: rgba(48, 48, 48, 1);
  border: 1px solid rgba(255, 255, 255, 0.05);

  @include flexContainer();

  &:hover {
    background-color: rgba(74, 74, 74, 1);
  }

  &.active {
    border: 2px solid $cream;
    .innerContainer {
      background-color: rgba(48, 48, 48, 1);
      @include textGradient();
      font-weight: 600;
    }
  }

  .innerContainer {
    @include flexContainer(row, center, center);
    width: 100%;
    height: 100%;
    padding: 6px 12px 6px 12px;
    border-radius: 8px;

    .icon {
      width: 20px;
      margin-right: 12px;
    }
  }
}
