@use '~/styles/modules' as *;

.BuyButton {
  font: $p2-bold;
  color: var(--background-strong);
  padding: 1.6rem 1.6rem;
  background: $cream-gradient;
  width: fit-content;
  border-radius: 3.2rem;
  text-transform: uppercase;
  @include flexContainer(row, center, center);

  &:hover {
    background: $cream;
  }

  &:active {
    background-color: var(--background-contrast);
  }

  &:focus-visible {
    background: var(--background-strong);
    text-decoration: underline;
    border: 2px solid var(--background-contrast);
    color: var(--background-contrast);
  }

  &:disabled {
    background:#4A4A4A99;
    cursor: not-allowed;
    color: #979797;
  }
}
