@use '~/styles/modules' as *;

.MediaControlButton {
  font-family: var(--font1);
  color: var(--text-color);
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background: rgba(74, 74, 74, 0.1);
  @include flexContainer(row, center, center);

  .icon {
    width: 60%;
    fill: var(--text-color);
  }
}
