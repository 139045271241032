@use '~/styles/modules' as *;

.Checkbox {
  font-family: var(--font1);
  color: var(--text-color);
  @include flexContainer();

  &:focus-visible {
    background-color: var(--background-strong);
    border-color: var(--text-color);

    span {
      text-decoration: underline;
    }
  }

  .icon {
    margin: auto;
    width: 16px;
    stroke: var(--background-strong);
  }

  span {
    height: 2.4rem;
    color: var(--text-color);
    align-items: center;
    position: absolute;
    left: 3.6rem;
    @include flexContainer();
    text-transform: capitalize;
    font: $h4;
  }
}
