@use '~/styles/modules' as *;

.Chip {
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  color: var(--text-color);
  padding: 8px 12px;
  background-color: var(--background1);
  height: 32px;
  border: 1px solid var(--background3);
  border-radius: 16px;
  @include flexContainer();
}
