.regularAvatar {
     width: 40px;
     height: 40px;
     border-radius: 50%;
     overflow: hidden;
}

.nftCollector {
     width: 100px;
     height: 100px;
     border-radius: 50%;
     overflow: hidden;
}