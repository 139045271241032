@use '~/styles/modules' as *;

.AvatarDropdownMenu {
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 6;
  padding-right: 24px;
  border-radius: 12px;

  .subTitle {
    font-size: 11px;
    font-weight: 500;
    margin-bottom: 6px;
    color: $grey3;
  }
  .dropdownIcon {
    cursor: pointer;
    position: absolute;
    top: 16px;
    right: 8px;
    width: 16px;
    transform: rotate(180deg);
    z-index: 10;
  }

  .divider {
    width: 100%;
    height: 1px;
    margin-bottom: 1rem;
    background-color: lightgrey;
  }

  .ulSmall {
    height: 100%;
    font: $p2;
    @include flexContainer(column, space-between, flex-start);
    margin: 0rem;
  }

  .collection {
    height: 3rem;
    font: $p2;
    @include flexContainer(column, space-between, flex-start);
    margin: 0rem;
  }

  .value {
    font: $p1;
    margin: 0.5rem 0;
  }

  .avatarAddress {
    @include flexContainer(row, space-between, center);
    height: 3rem;
    cursor: pointer;
    font-weight: 700;
    font-size: 14px;
    margin: 0.5rem 0;

    .identicon {
      border-radius: 50%;
      width: 32px;
      height: 32px;
      // overflow: hidden;
    }
    p{
      margin-left: 1rem;
    }

  }

  .firstCollectionTag {
    @include flexContainer(row, space-between, center);
    margin: 0.5rem 0;
    color: $cream;
    padding-left: 4px;
    font-weight: 700;
    font-size: 14px;
    .gem {
      width: 24px;
      height: 24px;
      margin-right: 12px;
    }
  }
  ul {
    width: 100%;
    height: 100%;
    @include flexContainer(column);

    li:not(:last-child) {
      margin: 0 auto 8px 0;
    }

    li {
      width: 100%;
      height: 2.4rem;
      @include flexContainer(row, flex-start, center);

      a,
      button {
        @include flexContainer(row, flex-start, center);
        width: 100%;
        text-transform: capitalize;
        padding: 0.8rem 0;
      }
    }
  }
}
