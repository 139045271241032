@use '~/styles/modules' as *;

.ShareButton {
  width: fit-content;
  border-radius: 3.2rem;
  background: var(--theme-grey3);
  border-radius: 32px;

  .index {
    position: relative;
    @include flexContainer();
    width: 100%;
    height: 100%;
    padding: 0.8rem 1.6rem;
  }

  @include flexContainer();

  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 3.2rem;
    background-color: white;
    opacity: 0.0;
  }

  .icon {
    height: 24px;
    fill: white;
    z-index: 1;
  }

  .label {
    margin: auto auto auto 0.6rem;
    font-size: 12px;
    line-height: 11px;
    font-weight: 900;
    letter-spacing: 0.24px;
    text-transform: uppercase;
    color: var(--text-color);
    z-index: 1;
  }

  &:hover {
    .overlay {
      opacity: 0.15;
    }
  }
  &:active {
    .overlay {
      background-color: black;
      opacity: 0.2;
    }
  }

  &:focus-visible {
    border-color: var(--text-color);
    .overlay {
      background-color: var(--background1);
    }
    .label {
      text-decoration: underline;
    }
  }
}
