@use '~/styles/modules' as *;

.NFTDetailsCard {
  font-family: var(--font1);
  color: var(--text-color);
  background: var(--background2);
  position: relative;
  border-radius: 1.6rem;
  box-shadow: $cardShadow3;
  height: auto;
  @include flexContainer(column, flex-start, flex-start);

  .innerContainer {
    padding: 2.4rem;
    width: 100%;
    overflow: auto;
  }

  .header {
    @include flexContainer(row, space-between, flex-start);
    width: 100%;
    & > *:not(:last-child) {
      margin-right: 0.8rem;
    }
    .nftTitle {
      font: $h1;
      width: auto;
    }

    .shareButton {
      position: relative;
    }
    .shareMenu {
      position: absolute;
      top: 5.4rem;
      right: 0;
    }
  }

  .metaRow {
    & > *:not(:last-child) {
      margin-right: 30px;
    }
  }

  .row {
    margin-top: 1rem;
    width: 100%;
    @include flexContainer(row, flex-start);
    flex-wrap: wrap;

    p {
      font: $p1;

      &.gradient {
        @include textGradient();
      }
    }

    .gemIcon {
      width: 2rem;
      margin: auto 8px auto 0;
    }
  }

  .metadata {
    @include flexContainer(row, flex-start, flex-start);
    margin-left: -1.6rem;
    margin-right: -1.6rem;

    & > * {
      flex: 0 0 auto;
      padding: 0 1.6rem;
    }
  }

  .description {
    font: $body;
    line-height: 150%;
    margin-top: 1.6rem;
    margin-bottom: 8px;
    word-break: break-word;

    p {
      margin-bottom: 8px;
    }

    a,
    button {
      color: $cream;
      font-weight: 600;
      text-decoration: underline;
    }
  }

  .seeMore {
    font: $h4_lighter;
    color: $cream;
    font-weight: 500;
    text-decoration: underline;
    text-align: right;
  }

  .column {
    @include flexContainer(column, flex-start, flex-start);
    margin-top: 2.4rem;

    .lightLabel {
      font: $overline11;
      color: var(--background5);
      margin-bottom: 0.4rem;
    }


    .innerRow {
      @include flexContainer(row, flex-start, center);
      position: relative;

      .avatar,
      .collectorTile {
        border-radius: 50%;
        margin-right: -1rem;
        width: 3.2rem;
        height: 3.2rem;
        border: 1px solid var(--background3);
        // overflow: hidden;
        @include flexContainer(row, center, center);

        .identicon {
          width: 100%;
          height: 100%;
          // overflow: hidden;
        }
      }
      .numberedTile {
        background-color: $grey1;
        position: absolute;
        left: 6.5rem;
        font-weight: 800;
        font-size: 11px;
        line-height: 16px;
        text-align: center;
        border-radius: 50%;
        margin-right: -1rem;
        width: 3.2rem;
        height: 3.2rem;
        border: 1px solid var(--background3);
        overflow: hidden;
        @include flexContainer(row, center, center);
      }

      .collectorAddress {
        font: $p1;
        margin-left: 16px;
        max-width: 80px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .creatorName {
        font: $p1;
        margin: auto auto auto 16px;
        @include desktop-screens() {
          flex-grow: 1;
        }
      }

      .tokenName {
        font: $p1;
        margin: -2px auto auto 0;
        cursor: pointer;

        > svg {
          position: relative;
          top: 6px;
        }
        @include desktop-screens() {
          max-width: 170px;
        }
      }

      .collectionTilesContainer,
      .collectorsListTileContainer {
        position: relative;
        display: flex;
        flex-direction: row-reverse;

        .nftTile {
          margin-right: -1rem;
          position: relative;
          width: 3.2rem;
          height: 3.2rem;
          overflow: hidden;

          * img {
            border: 1px solid var(--background3) !important;
            border-radius: 4px;
          }

          &:nth-child(1){
            filter: brightness(1);
            z-index: 3;
          }
          &:nth-child(2){
            filter: brightness(0.8);
            z-index: 2;
          }
          &:nth-child(3){
            filter: brightness(0.6);
            z-index: 1;
          }

          * video {
            border: 1px solid var(--background3) !important;
            border-radius: 4px;
          }
        }
      }
    }
  }

  .bottomBar {
    margin-top: auto;
    width: 100%;
  }
}

.artwork {
  text-transform: capitalize;
}

.artworkExclusive {
  text-transform: capitalize;
  color: #F8F360;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 125%;
}

.iconExclusive {
  margin-right: 5px !important;
  position: relative;
  top: 1px;
}

.modalLink {
  color: var(--theme-cream) !important;
  position: relative;
  left: 0;
  font: 700 14px/125% Inter, sans-serif !important;

  @include medium-screens() {
    left: -8px;
  }
}

.headerModal {
  font: $h1-bolder;
  font-weight: bold !important;
  font-size: 16px;
  margin-bottom: 24px !important;

  @include medium-screens() {
    margin-top: 4px !important;
    margin-bottom: 32px !important;
    font-size: 20px;
  }
}

.linkIcon {
  width: 16px;
  position: relative;
  top: 2px !important;
  left: 8px;

  > path:nth-of-type(1) {
    fill: var(--theme-cream) !important;
  }
}

.collectorByName {
  margin-left: -16px;
  display: flex;
  align-items: center;
  height: 32px;
}